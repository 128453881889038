import { useEffect, useState } from "react";

import { Plus } from "@phosphor-icons/react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  BusinessesManagementQuery,
  useBusinessesManagementQuery,
} from "../../../generated/graphql";
import BusinessPage from "../BusinessPage";
import BusinessesList from "./BusinessesList";
import matchesSearch from "../../../lib/generic/matchesSearch";
import Button from "../../../components/buttons/Button";
import NewBusinessModal from "./NewBusinessModal";
import SearchInput from "../../../components/SearchInput";
import VerifyAndInviteEmailButton from "../VerifyAndInviteEmailButton";
import ErrorMessageCard from "../../../components/ErrorMessageCard";
import CardGroup from "../../../components/CardGroup";

export default function TeamsPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Teams - Hypertune";
  }, []);

  const navigate = useNavigate();

  const { error, data } = useBusinessesManagementQuery();

  const [searchText, setSearchText] = useState<string>("");
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  if (!isRedirecting && data?.primaryBusiness?.business.type === "Business") {
    return <Navigate to="/team" />;
  }

  return (
    <>
      <BusinessPage>
        <div className="mb-5 flex w-full justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-lg font-semibold">Your teams</p>
            {data?.businesses.length === 1 && (
              <div className="mb-[6px] text-tx-muted">
                Create a new team to share your projects with others.
              </div>
            )}
          </div>
          <div className="flex flex-row gap-2">
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
            />
            <Button
              text="New team"
              onClick={() => {
                setShowNewTeamModal(true);
              }}
              icon={<Plus weight="bold" color="white" />}
              intent="primary"
              weight="filled"
              size="large"
            />
          </div>
        </div>
        {error ? (
          <ErrorMessageCard error={error} />
        ) : (
          <TeamsInner
            searchText={searchText}
            data={data}
            setIsRedirecting={setIsRedirecting}
          />
        )}
      </BusinessPage>
      {showNewTeamModal && (
        <NewBusinessModal
          onCreateStart={() => setIsRedirecting(true)}
          onClose={async (newTeamId) => {
            setShowNewTeamModal(false);
            if (newTeamId) {
              await navigate("/team");
            }
            setIsRedirecting(false);
          }}
        />
      )}
    </>
  );
}

function TeamsInner({
  searchText,
  data,
  setIsRedirecting,
}: {
  searchText: string;
  data?: BusinessesManagementQuery;
  setIsRedirecting: (newIsRedirecting: boolean) => void;
}): React.ReactElement {
  const allBusinesses =
    data?.businesses
      .filter((business) => business.type !== "Personal")
      .map((business) => {
        return {
          id: business.id.toString(),
          name: business.name,
          memberCount: business.memberCount,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  const filteredBusinesses = allBusinesses.filter((business) =>
    matchesSearch(searchText, [business.name])
  );

  return (
    <>
      {!data && (
        <CardGroup
          layout="list"
          cardLayout="horizontal-with-icon"
          loadingCount={4}
          cards={[]}
        />
      )}
      {data && filteredBusinesses.length > 0 && (
        <BusinessesList
          meId={data.me.id}
          allBusinesses={filteredBusinesses}
          selectedBusinessId={data.primaryBusiness?.id}
          onSwitchBusinessStart={() => setIsRedirecting(true)}
          onSwitchBusinessEnd={() => setIsRedirecting(false)}
        />
      )}
      {data && !data.me.emailVerified && (
        <div className="mt-7 flex items-center justify-between pb-5">
          <div className="flex flex-col gap-1">
            <p className="text-lg font-semibold">Join other teams</p>
            <div className="mb-[6px] text-tx-muted">
              Verify your email to join other teams you've been invited to.
            </div>
          </div>
          <VerifyAndInviteEmailButton
            text="Verify your email"
            weight="filled"
            size="large"
          />
        </div>
      )}
    </>
  );
}
